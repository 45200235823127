<!--
--------------------------------------------------------------------------------
<copyright file="SalesAmountVsNumberOfQuotesBySalesperson.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.quotes_conv_rates') }}</summary>
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold">
            {{ $t('widget_info_text.quotes_conv_rates_amount') }}
          </span>
          - {{ $t('widget_info_text.quotes_conv_rates_amount_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold">
            {{ $t('widget_info_text.quotes_conv_rates_quotes') }}
          </span>
          - {{ $t('widget_info_text.quotes_conv_rates_quotes_txt') }}
        </li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.X-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.quotes_conv_rates_xaxis') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.salespeople') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.bars') }}</summary>
      <p class="text">{{ $t('widget_info_text.salespeople_conversion_rates_bars') }}</p>
      <ul class="list">
        <li class="list-item">
          <span>{{ $t('widget_info_text.quotes_conv_rates_amount') }}</span>
        </li>
        <li class="list-item">
          <span>{{ $t('widget_info_text.quotes_conv_rates_quotes') }}</span>
        </li>
      </ul>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <ul class="list tip-bkg">
        <li class="list-item2">{{ $t('widget_info_text.bars_tip_txt_cat') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.tooltip_txt') }}</li>
      </ul>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class SalesAmountVsNumberOfQuotesBySalesperson extends Vue {}
</script>
